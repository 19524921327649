import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform } from 'react-native';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import DashedBorder from 'react-native-dashed-border';
import path from './serverPath.js'


class Login extends Component {

    //   handlePress = payment_methods => {
    //     this.props.history.push('/showPayment', payment_methods);
    //   };

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            uuid: "",
            token: "",
            logined: false,
        };
    }

    handleUserNameChange(event = {}) {
        const username = event;
        this.setState({
            username: username
        });
    }

    handlePasswordChange(event = {}) {
        const password = event;
        this.setState({
            password: password
        });
    }

    onPressRegisterButton() {
        this.props.history.push('/register');
    }

    login() {
        login(this.state.username, this.state.password, this.state.uuid, this.state.token).then(data => {
            if (data.result == 408) {
                if (Platform.OS === 'web') {
                    alert(data.result_message)
                } else {
                    Alert.alert(
                        'Please Try Again',
                        data.result_message,
                        [
                            { text: 'OK', onPress: () => console.log('OK Pressed') },
                        ],
                        { cancelable: false }
                    )
                }
            } else {
                const user_info = JSON.stringify(data.data.user_info)
                AsyncStorage.setItem('user_info', user_info)
                AsyncStorage.removeItem("app_user_info");
                this.setState({
                    logined: true,
                })
            }
        });
    }

    onPressButton() {
        login(this.state.username, this.state.password, this.state.uuid, this.state.token).then(data => {

            if (data.result == 408) {
                if (Platform.OS === 'web') {
                    alert(data.result_message)
                } else {
                    Alert.alert(
                        'Please Try Again',
                        data.result_message,
                        [
                            { text: 'OK', onPress: () => console.log('OK Pressed') },
                        ],
                        { cancelable: false }
                    )
                }
            } else {
                const user_info = JSON.stringify(data.data.user_info)
                AsyncStorage.setItem('user_info', user_info)
                AsyncStorage.removeItem("app_user_info");
                this.setState({
                    logined: true,
                })
            }
        });
    }


    async componentDidMount() {
        try {
            this.state.uuid = await AsyncStorage.getItem('uuid')
            this.state.token = await AsyncStorage.getItem('token')
        } catch (error) {

        }
    }

    render() {
        if (!this.state.logined) {
            return (
                <View style={styles.container}>
                    <Image style={styles.image} source={require('./image/hippos_banner.png')} />
                    <View style={styles.login_border}>
                        <Text style={styles.listedItem}>Username</Text>
                        <TextInput
                            style={styles.input}
                            textContentType="username"
                            placeholder="Email"
                            onChangeText={this.handleUserNameChange.bind(this)}
                            value={this.state.username}
                        />
                        <Text style={styles.listedItem}>Password</Text>
                        <TextInput
                            style={styles.input}
                            secureTextEntry={true}
                            textContentType="password"
                            placeholder="Password"
                            onChangeText={this.handlePasswordChange.bind(this)}
                            onSubmitEditing={this.login.bind(this)}
                            value={this.state.password}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="Login"
                            onPress={this.onPressButton.bind(this)}
                        />
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="Register"
                            onPress={this.onPressRegisterButton.bind(this)}
                        />
                    </View>

                </View>
            );
        } else {
            return (
                <Redirect to={{
                    pathname: '/',
                }}
                />
            )
        }
    }
}

async function login(email, password, uuid, token) {
    try {
        return fetch(path + 'login', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                email: email,
                password: password,
                uuid: uuid,
                token: token,
                push_key: "null",
                version: '1',
                device: 'React Native',
                os_version: '1',
                platform: 'ios',
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    input: {
        fontSize: 20,
        margin: 5,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 20,
        width: '40%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10, alignSelf: 'center',
        resizeMode: "stretch",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    }
});

export default Login;