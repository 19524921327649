import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform } from 'react-native';
import { Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'

class StockIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            product :null,
            uuid: '',
            token: '',
            user_info: '',
            product_id: '',
            product_name: '',
            sku: '',
            bar_code: '',
            packing: '',
            batch_number: '',
            cost: '',
            qty: '',
            shop_id: '',
        };
    }

    onPressBack = () => {
        this.props.history.push('/editProduct', this.state.product);
    }

    async onPressButton() {
        var product_array = { 'product_id': this.state.product_id, 'qty': Number(this.state.qty), 'cost': Number(this.state.cost) }
        const userInfo = JSON.parse(this.state.user_info);

        stockIn(userInfo.email, this.state.uuid, this.state.token, this.state.shop_id, JSON.stringify(product_array), this.state.batch_number).then(data => {
            this.props.history.push('/editProduct', this.props.location.state);
        });
    }


    async componentDidMount() {
        const product = this.props.location.state
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');

        this.setState({
            product,
            uuid, token, user_info,
            product_id: product.id,
            product_name: product.name_en,
            sku: product.sku,
            bar_code: product.bar_code,
            packing: product.packing_en,
            shop_id: product.shops[0].id
        })
    }

    render() {
        return (
            <View style={styles.container}>
                <Image style={styles.image} source={require('./image/hippos_banner.png')} />
                <Text style={styles.title}>存貨入庫 Stock In</Text>
                <View style={styles.login_border}>
                    <Text style={styles.listedItem}>
                        貨品名稱Product Name(EN) : {this.state.product_name}</Text>
                    <Text style={styles.listedItem}>
                        SKU : {this.state.sku}</Text>
                    <Text style={styles.listedItem}>
                        條碼Barcode: {this.state.bar_code}</Text>
                    <Text style={styles.listedItem}>
                        包裝描述Packing(EN) : {this.state.packing}</Text>

                    <Text style={styles.listedItem}>批次編號Batch Number :</Text>
                    <TextInput
                        style={styles.input}
                        textContentType="none"
                        placeholder="批次編號Batch Number"
                        onChangeText={(text) => { this.setState({ batch_number: text }) }}
                        value={this.state.batch_number}
                    />
                    <Text style={styles.listedItem}>成本Cost</Text>
                    <TextInput
                        style={styles.input}
                        textContentType="number"
                        placeholder="成本Cost"
                        onChangeText={(text) => { this.setState({ cost: text }) }}
                        value={this.state.cost}
                    />
                    <Text style={styles.listedItem}>數量Qty</Text>
                    <TextInput
                        style={styles.input}
                        textContentType="number"
                        placeholder="數量Qty"
                        onChangeText={(text) => { this.setState({ qty: text }) }}
                        value={this.state.qty}
                    />
                    <Button
                        large
                        title="存貨入庫 Stock In"
                        onPress={this.onPressButton.bind(this)}
                    />

                    <Button
                        style={{ marginTop: 10 }}
                        large
                        title="返回 Back"
                        onPress={this.onPressBack.bind(this)}
                    />
                </View>

            </View>
        );
    }
}

async function stockIn(email, uuid, token, shop_id, products, batch_number) {

    const data = new FormData();

    data.append('username', email)
    data.append('uuid', uuid);
    data.append('shop_id', shop_id);
    data.append('token', token);
    data.append('products', '[' + products + ']')
    data.append('batch_number', batch_number)


    try {
        return fetch(path + 'inventory/stockIn', {
            method: 'POST',
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
            _body: data,
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginTop: 10,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10, alignSelf: 'center',
        resizeMode: "stretch",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
});

export default StockIn;