import React, { Component } from 'react';
import { Image, StyleSheet, View, Text, FlatList, TouchableOpacity } from 'react-native';
import { ThemeProvider, Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import path from './serverPath.js'


class OrderList extends Component {


  handlePress = order => {
    this.props.history.push('/orderDetail', order);
    // this.props.history.push({
    //   pathname: '/stockOut',
    //   state: { stock: stocks, product: this.state.product }
    // })
  };

  handlePagePress = page => {
    stockHistories(uuid, token, userInfo.email, '1', shop_id).then(data => {
      var pages = [];
      for (var i = 1; i <= data.data.pagination.total_page; i++) {
        pages.push(i);
      }

      var stocks = data.data.stocks
      for (let stock of stocks) {
        if (stock.product_cover_image == "" || stock.product_cover_image == null) {
          stock.cover = 'http://hippos.beuniquewedding.com/img/icon.png'
        }
      }

      this.setState({
        stocks,
        pagination: data.data.pagination,
        pages,
      });
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      orders: null,
      pagination: null,
      pages: null,
      shops: null,
      uuid: '',
      token: '',
      username: '',
    };
  }

  async componentDidMount() {
    const uuid = await AsyncStorage.getItem('uuid');
    const token = await AsyncStorage.getItem('token');
    const user_info = await AsyncStorage.getItem('user_info');
    const userInfo = JSON.parse(user_info);
    const shops = JSON.parse(await AsyncStorage.getItem('shops'))

    getOrders(uuid, token, userInfo.email, '1').then(data => {
      var pages = [];
      for (var i = 1; i <= data.data.pagination.total_page; i++) {
        pages.push(i);
      }

      var orders = data.data.orders

      this.setState({
        shops,
        orders,
        pagination: data.data.pagination,
        pages,
        uuid,
        token,
        username: userInfo.email,
      });
    });
  }

  onPressBack = () => {
    this.props.history.push('/');
  }

  render() {
    return (

      <View >
        <View style={{ flexDirection: 'row', width: "100%" }}>
          <Image style={styles.image} source={require('./image/hippos_banner.png')} />
          <View style={{ marginLeft: 20, padding: 10, flexDirection: 'column-reverse' }}>
            <Button
              large
              title="返回 Back"
              onPress={this.onPressBack.bind(this)}
            />
          </View>
        </View>

        <View style={styles.border}>
          <FlatList
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            data={this.state.orders}
            showsVerticalScrollIndicator={false}

            renderItem={({ item }) => {
              if (item.status == 'completed') {
                return (
                  <TouchableOpacity onPress={() => this.handlePress(item)}>
                    <View style={styles.list_border_w_color}>
                      <View>
                        <Text style={styles.listedItemBold}>{`帳單編號 Order Number :` + item.order_number}</Text>
                        <Text style={styles.listedItem}>{`下單店鋪 Ordering Shop : ` + this.state.shops.find(shop => shop.shop_id === item.shop_id).name}</Text>
                        <Text style={styles.listedItem}>{`訂單總計 Total Price : HKD$` + item.amount}</Text>
                        <Text style={styles.listedItem}>{`下單時間 Order Date :  ` + item.created_at}</Text>
                        <Text style={styles.listedItemComplete}>{`訂單狀態 Order Status :  已完成 ` + item.status}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              } else if (item.status == 'pending') {
                return (
                  <TouchableOpacity onPress={() => this.handlePress(item)}>
                    <View style={styles.list_border_w_color}>
                      <View>
                        <Text style={styles.listedItemBold}>{`帳單編號 Order Number :` + item.order_number}</Text>
                        <Text style={styles.listedItem}>{`下單店鋪 Ordering Shop : ` + this.state.shops.find(shop => shop.shop_id === item.shop_id).name}</Text>
                        <Text style={styles.listedItem}>{`訂單總計 Total Price : HKD$` + item.amount}</Text>
                        <Text style={styles.listedItem}>{`下單時間 Order Date :  ` + item.created_at}</Text>
                        <Text style={styles.listedItemPending}>{`訂單狀態 Order Status :  處理中 ` + item.status}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              } else {
                return (
                  <TouchableOpacity onPress={() => this.handlePress(item)}>
                    <View style={styles.list_border_w_color}>
                      <View>
                        <Text style={styles.listedItemBold}>{`帳單編號 Order Number :` + item.order_number}</Text>
                        <Text style={styles.listedItem}>{`下單店鋪 Ordering Shop : ` + this.state.shops.find(shop => shop.shop_id === item.shop_id).name}</Text>
                        <Text style={styles.listedItem}>{`訂單總計 Total Price : HKD$` + item.amount}</Text>
                        <Text style={styles.listedItem}>{`下單時間 Order Date :  ` + item.created_at}</Text>
                        <Text style={styles.listedItem}>{`訂單狀態 Order Status :  已取消 ` + item.status}</Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                );
              }
            }}

            keyExtractor={item => item.order_id.toString()}
          />

          <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
            <Text style={{ fontSize: 18, marginRight: 5 }}>分頁Page:</Text>
            <FlatList
              data={this.state.pages}
              ItemSeparatorComponent={this.space}
              horizontal={true}
              renderItem={({ item }) =>
                <TouchableOpacity onPress={() => this.handlePagePress(item)}>
                  <Text style={styles.listedPageNum}>{item}</Text>
                </TouchableOpacity>
              }
            />
          </View>
        </View>
      </View >
    );
  }
}

async function getOrders(uuid, token, email, page) {
  try {
    return fetch(path + 'sales/orders', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
        uuid: uuid,
        token: token,
        username: email,
        page: page,
        all_langs: 'yes',
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

const styles = StyleSheet.create({
  listedItem: {
    fontSize: 16,
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedItemComplete: {
    fontSize: 16,
    color: '#62C377',
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedItemCancel: {
    fontSize: 16,
    color: 'red',
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedItemPending: {
    fontSize: 16,
    color: '#59A0E8',
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedItemBold: {
    fontWeight: "bold",
    fontSize: 18,
    // background: '#285b97',
    borderRadius: 3,
    padding: 5,
    margin: 5,
  },
  listedPageNum: {
    fontSize: 18,
    marginRight: 5,
    textDecorationLine: 'underline',
    color: 'blue'
  },
  image: {
    resizeMode: "stretch",
    width: '30%',
    height: 100,
  }, border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
  },
  cover: {
    resizeMode: "stretch",
    flex: 1,
    width: 120,
    height: 100,
  },
  list_border: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'row'
  },
  list_border_w_color: {
    marginTop: 10,
    width: '100%',
    borderColor: '#000',
    borderRadius: 5,
    borderWidth: 2,
    padding: 10,
    flexGrow: 1,
    flexDirection: 'row'
  }
});


export default OrderList;