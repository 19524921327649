import React, { Component } from 'react';
import { View, Text, FlatList, TouchableOpacity } from 'react-native';
import ShowPayment from './ShowPayment';

class PaymentMethod extends Component {


  handlePress = payment_methods => {
    this.props.history.push('/showPayment', payment_methods);
  };

  constructor(props) {
    super(props);
    this.state = {
      payment_methods: null
    };
  }

  componentDidMount() {
    getAppChecking().then(data => {
      this.setState({
        payment_methods: data.data.payment_methods
      });
    });
  }

  render() {
    return (
      <View >
        <FlatList
          data={this.state.payment_methods}
          showsVerticalScrollIndicator={false}
          renderItem={({ item }) =>
            <TouchableOpacity onPress={() => this.handlePress(item)}>
              <Text>{item.name}</Text>
            </TouchableOpacity>
          }
          keyExtractor={item => item.name}
        />
      </View>
    );
  }
}

async function getAppChecking() {
  try {
    return fetch(path + 'appChecking', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      _body: JSON.stringify({
        version: '1',
        platform: 'ios',
      }),
      get body() {
        return this._body;
      },
      set body(value) {
        this._body = value;
      },
    })
      .then((response) => response.json())
    // return responseJson.data.payment_methods;
  }
  catch (error) {
    console.error(error);
  }
}

export default PaymentMethod;