import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { Router, Switch, Route } from './routing';
import PaymentMethod from './PaymentMethod';
import ShowPayment from './ShowPayment';
import Login from './Login';
import AppChecking from './AppChecking';
import Productlist from './ProductList';
import ShowProduct from './ShowProduct';
import CreateProduct from './CreateProduct';
import EditProduct from './EditProduct';
import CreateCategory from './CreateCategory';
import Register from './Register'
import StockIn from './StockIn'
import StockOutList from './StockOutList'
import StockOut from './StockOut'
import SelectAction from './SelectAction'
import OrderList from './OrderList'
import OrderDetail from './OrderDetail'

class App extends Component {

  render() {
    return (
      <View style={styles.container}>
        <Router>
          <Switch>
            {/* <Route
              exact
              path="/"
              render={props => (
                <PaymentMethod 
                {...props} 
                />
              )}
            />
            <Route
              path="/showPayment"
              render={props => (
                <ShowPayment
                  {...props}
                />
              )}
            /> */}
            <Route
              exact
              path="/"
              render={props => (
                <AppChecking
                  {...props}
                />
              )}
            />
            <Route
              path="/login"
              render={props => (
                <Login
                  {...props}
                />
              )}
            />
            <Route
              path="/productlist"
              render={props => (
                <Productlist
                  {...props}
                />
              )}
            />
            <Route
              path="/showProduct"
              render={props => (
                <ShowProduct
                  {...props}
                />
              )}
            />
            <Route
              path="/createProduct"
              render={props => (
                <CreateProduct
                  {...props}
                />
              )}
            />
            <Route
              path="/editProduct"
              render={props => (
                <EditProduct
                  {...props}
                />
              )}
            />
            <Route
              path="/createCategory"
              render={props => (
                <CreateCategory
                  {...props}
                />
              )}
            />
            <Route
              path="/register"
              render={props => (
                <Register
                  {...props}
                />
              )}
            />
            <Route
              path="/stockIn"
              render={props => (
                <StockIn
                  {...props}
                />
              )}
            />
            <Route
              path="/stockOutList"
              render={props => (
                <StockOutList
                  {...props}
                />
              )}
            />
            <Route
              path="/stockOut"
              render={props => (
                <StockOut
                  {...props}
                />
              )}
            />
            <Route
              path="/selectAction"
              render={props => (
                <SelectAction
                  {...props}
                />
              )}
            />
            <Route
              path="/orderList"
              render={props => (
                <OrderList
                  {...props}
                />
              )}
            />
            <Route
              path="/orderDetail"
              render={props => (
                <OrderDetail
                  {...props}
                />
              )}
            />
          </Switch>
        </Router>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: '5%',
    paddingLeft: '15%',
    paddingRight: '15%',
    justifyContent: 'center',
  }
});

export default App;