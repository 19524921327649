import React, { Component } from 'react';
import { Image, StyleSheet, Alert, View, TextInput, Text, Platform } from 'react-native';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/FontAwesome';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import DashedBorder from 'react-native-dashed-border';
import path from './serverPath.js'

class Register extends Component {

    //   handlePress = payment_methods => {
    //     this.props.history.push('/showPayment', payment_methods);
    //   };

    constructor(props) {
        super(props);
        this.state = {
            warning_company_name: false,
            warning_email: false,
            warning_password: false,
            warning_confirm_password: false,
            warning_verification_code: false,
            warning_country_code: false,
            warning_phone: false,
            warning_password_not_match: false,

            company_name: "",
            email: "",
            password: "",
            confirm_password: "",
            verification_code: "",
            country_code: "852",
            phone: "",
            uuid: "",
            token: "",
            counting_text: "Send Code",
            count_time: 60,
            is_timer: false,
        };
    }

    onSendVerification() {
        if (this.state.email === "") {
            this.setState({ warning_email: true })
        } else {
            this.setState({ warning_email: false })

            if (!this.state.is_timer) {
                this.setState({
                    is_timer: true
                })
                this.interval = setInterval(
                    () => this.setState((prevState) => ({
                        count_time: prevState.count_time - 1,
                        counting_text: prevState.count_time - 1
                    })),
                    1000
                );
                sendVerification(this.state.email, this.state.uuid, this.state.token).then(data => {
           
                });
            }
        }
    }


    onPressButton() {
        this.setState({
            warning_company_name: false,
            warning_email: false,
            warning_password: false,
            warning_confirm_password: false,
            warning_verification_code: false,
            warning_country_code: false,
            warning_phone: false,
            warning_password_not_match: false,
        })

        if (this.state.email != "" &&
            this.state.company_name != "" &&
            this.state.phone != "" &&
            this.state.country_code != "" &&
            this.state.confirm_password != "" &&
            this.state.verification_code != "" &&
            this.state.password != "") {
            if (this.state.password != this.state.confirm_password) {
                this.setState({
                    warning_password_not_match: true
                })
            } else {
                register(this.state.uuid, this.state.token,
                    this.state.email, this.state.password,
                    this.state.company_name, this.state.verification_code,
                    this.state.country_code, this.state.phone).then(data => {
                        if(data.result == 0){
                            this.props.history.push('/');
                        }
                    });
            }
        }
        if (this.state.email === "") {
            this.setState({ warning_email: true })
        }
        if (this.state.company_name === "") {
            this.setState({ warning_company_name: true })
        }
        if (this.state.phone === "") {
            this.setState({ warning_phone: true })
        }
        if (this.state.country_code === "") {
            this.setState({ warning_country_code: true })
        }
        if (this.state.confirm_password === "") {
            this.setState({ warning_confirm_password: true })
        }
        if (this.state.verification_code === "") {
            this.setState({ warning_verification_code: true })
        }
        if (this.state.password === "") {
            this.setState({ warning_password: true })
        }
    }

    componentDidUpdate() {
        if (this.state.count_time === 1) {
            clearInterval(this.interval);
            this.setState({
                counting_text: "Send Code",
                count_time: 60,
                is_timer: false
            })
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    async componentDidMount() {
        try {
            this.state.uuid = await AsyncStorage.getItem('uuid')
            this.state.token = await AsyncStorage.getItem('token')
        } catch (error) {

        }
    }

    render() {
        return (
            <View style={styles.container}>
                <Image style={styles.image} source={require('./image/hippos_banner.png')} />
                {/* <Text style={{ fontSize: 26, marginTop: 20 }}>Register</Text> */}
                <View style={styles.login_border}>
                    {this.state.warning_company_name &&
                        <Text style={styles.warning_item}>請輸入公司名稱Company Name</Text>}
                    {this.state.warning_email && <Text style={styles.warning_item}>請輸入電郵地址Email</Text>}
                    {this.state.warning_verification_code && <Text style={styles.warning_item}>請輸入驗證碼Verification Code</Text>}
                    {this.state.warning_password && <Text style={styles.warning_item}>請輸入密碼Password</Text>}
                    {this.state.warning_confirm_password && <Text style={styles.warning_item}>請輸入確認密碼Confirm Password</Text>}
                    {this.state.warning_country_code && <Text style={styles.warning_item}>請輸入電話區碼Calling Code</Text>}
                    {this.state.warning_phone && <Text style={styles.warning_item}>請輸入電話號碼Phone</Text>}
                    {this.state.warning_password_not_match && <Text style={styles.warning_item}>兩次密碼不相符Password Not Match</Text>}

                    <Text style={styles.listedItem}>公司名稱Company Name</Text>
                    <TextInput
                        style={styles.input}
                        textContentType="text"
                        placeholder="公司名稱Company Name"
                        onChangeText={(text) => { this.setState({ company_name: text }) }}
                        value={this.state.company_name}
                    />
                    <Text style={styles.listedItem}>電郵地址Email</Text>
                    <TextInput
                        style={styles.input}
                        textContentType="email"
                        placeholder="電郵地址Email"
                        onChangeText={(text) => { this.setState({ email: text }) }}
                        value={this.state.email}
                    />
                    <Text style={styles.listedItem}>驗證碼Verification Code</Text>
                    <View style={{ flexDirection: 'row', width: "100%" }}>
                        <TextInput
                            style={styles.input}
                            textContentType="text"
                            placeholder="驗證碼Verification Code"
                            onChangeText={(text) => { this.setState({ verification_code: text }) }}
                            value={this.state.verification_code}
                        />
                        <Button
                            large
                            title={`${this.state.counting_text}`}
                            onPress={this.onSendVerification.bind(this)}
                        />
                    </View>
                    <Text style={styles.listedItem}>密碼Password</Text>
                    <TextInput
                        style={styles.input}
                        // secureTextEntry={true}
                        textContentType="password"
                        placeholder="密碼Password"
                        onChangeText={(text) => { this.setState({ password: text }) }}
                        value={this.state.password}
                    />
                    <Text style={styles.listedItem}>確認密碼Confirm Password</Text>
                    <TextInput
                        style={styles.input}
                        // secureTextEntry={true}
                        textContentType="password"
                        placeholder="確認密碼Confirm Password"
                        onChangeText={(text) => { this.setState({ confirm_password: text }) }}
                        value={this.state.confirm_password}
                    />
                    <Text style={styles.listedItem}>電話號碼Phone</Text>
                    <View style={{ flexDirection: 'row', width: "100%" }}>
                        <TextInput
                            style={styles.country_code}
                            // secureTextEntry={true}
                            textContentType="number"
                            placeholder="+852"
                            onChangeText={(text) => { this.setState({ country_code: text }) }}
                            value={this.state.country_code}
                        />
                        <TextInput
                            style={styles.phone}
                            // secureTextEntry={true}
                            textContentType="number"
                            placeholder="電話號碼Phone"
                            onChangeText={(text) => { this.setState({ phone: text }) }}
                            value={this.state.phone}
                        />
                    </View>
                    <Button
                        style={{ marginTop: 10 }}
                        large
                        title="Register"
                        onPress={this.onPressButton.bind(this)}
                    />
                </View>
            </View>
        );
    }
}

async function sendVerification(email, uuid, token) {
    try {
        return fetch(path + 'sendVerification', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                email: email,
                uuid: uuid,
                token: token,
                push_key: "null",
                version: '1',
                device: 'React Native',
                os_version: '1',
                platform: 'ios',
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

async function register(uuid, token, email, password, company_name, verification_code, country_code, phone) {
    try {
        return fetch(path + 'register', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                email: email,
                password: password,
                company_name: company_name,
                verification_code: verification_code,
                country_code: country_code,
                phone: phone,
                uuid: uuid,
                token: token,
                first_name : company_name,
                last_name : company_name,
                push_key: "null",
                version: '1',
                device: 'React Native',
                os_version: '1',
                platform: 'ios',
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
       
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    warning_item: {
        color: 'red',
        fontSize: 20,
        margin: 5,
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    country_code: {
        flex: 0.1,
        width: "100%",
        fontSize: 20,
        margin: 5,
        textAlign: 'center',
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    phone: {
        flex: 0.9,
        width: "100%",
        fontSize: 20,
        margin: 5,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    input: {
        width: "100%",
        fontSize: 20,
        margin: 5,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    login_border: {
        marginBottom: 50,
        marginTop: 20,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10, alignSelf: 'center',
        resizeMode: "stretch",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    }
});

export default Register;