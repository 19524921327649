import React, { Component } from 'react';
import { View, Text, Image } from 'react-native';
import { Link } from './routing';
class ShowProduct extends Component {

  constructor(props) {
    super(props);
    this.state = {
      payment_method: null
    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <View >
        <Link to="/productlist">
          <Text>Go Back</Text>
        </Link>
        <View>
          <Text>{`#id : ${this.props.location.state.id}`}</Text>
        </View>
        <View>
          <Text>{this.props.location.state.name}</Text>
        </View>
        <View>
          <Text>{this.props.location.state.created_at}</Text>
        </View>
      </View>
    );
  }
}


export default ShowProduct;