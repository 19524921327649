import React, { Component } from 'react';
import { Image, StyleSheet, FlatList, View, TextInput, Text, ActivityIndicator, Platform } from 'react-native';
import { Button } from 'react-native-elements';
import { AsyncStorage } from 'react-native';
import { Redirect } from 'react-router';
import path from './serverPath.js'

class OrderDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: null,
        };
    }

    async onPressButton() {
        this.props.history.push('/orderList');
    }
    async componentDidMount() {
        const uuid = await AsyncStorage.getItem('uuid');
        const token = await AsyncStorage.getItem('token');
        const user_info = await AsyncStorage.getItem('user_info');
        const userInfo = JSON.parse(user_info);

        getOrderDetail(uuid, token, userInfo.email, this.props.location.state.order_id).then(data => {
            var order = data.data.order
            this.setState({
                order,
            });
        });
    }

    render() {
        if (this.state.order != null) {
            return (
                <View style={styles.container}>
                    <Image style={styles.image} source={require('./image/hippos_banner.png')} />
                    <Text style={styles.title}>帳單詳情 Order Detail</Text>
                    <View style={styles.login_border}>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>帳單編號Order Number</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.order_number}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>下單店鋪 Ordering Shop</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.shop.name}</Text>
                        </View>
                        {/* <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>下單員工 Ordering Staff</Text>
                            <Text style={styles.rightlistedItem}>{this.state.order.user_info.name}</Text>
                        </View> */}
                        <Text style={styles.orderItem}>下單項目 Ordering Item</Text>
                        <FlatList
                            // contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
                            data={this.state.order.products}
                            extraData={this.state}
                            renderItem={({ item }) =>
                                <View style={styles.list_border}>
                                    <View style={{ flexDirection: 'row', width: "100%" }}>
                                        <Text style={styles.leftorderItem}>{`貨品名稱Name : ` + item.name}</Text>
                                        <Text style={styles.rightlistedItem}>{`數量Qty : ` + item.qty}</Text>
                                    </View>
                                </View>
                            }
                            keyExtractor={item => item.product_id}
                        />
                        <View style={{ flexDirection: 'row', width: "100%" }}>
                            <Text style={styles.leftlistedItem}>訂單總計 Total Price</Text>
                            <Text style={styles.rightlistedItem}>{`HKD$` + this.state.order.amount}</Text>
                        </View>
                        <Button
                            style={{ marginTop: 10 }}
                            large
                            title="完成 Finish"
                            onPress={this.onPressButton.bind(this)}
                        />
                    </View>
                </View>
            )
        } else {
            return (
                <View style={[styles.container, styles.horizontal]}>
                    <ActivityIndicator size="large" color="#0000ff" />
                </View>
            )
        }
    }
}

async function getOrderDetail(uuid, token, email, order_id) {
    try {
        return fetch(path + 'sales/orderDetail', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            _body: JSON.stringify({
                version: '1',
                platform: 'ios',
                uuid: uuid,
                token: token,
                username: email,
                order_id: order_id,
                all_langs: 'yes',
            }),
            get body() {
                return this._body;
            },
            set body(value) {
                this._body = value;
            },
        })
            .then((response) => response.json())
        // return responseJson.data.payment_methods;
    }
    catch (error) {
        console.error(error);
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    listedItem: {
        fontSize: 20,
        margin: 5,
    },
    title: {
        marginTop: 10,
        fontSize: 30,
    },
    input: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border
        height: 30
    },
    inputArea: {
        fontSize: 20,
        margin: 5,
        marginBottom: 10,
        borderWidth: 2,  // size/width of the border
        borderColor: 'lightgrey',  // color of the border

    },
    login_border: {
        marginTop: 10,
        width: '70%',
        borderColor: '#000',
        borderRadius: 5,
        borderWidth: 2,
        padding: 10,
    },
    image: {
        padding: 10, alignSelf: 'center',
        resizeMode: "stretch",
        flex: 1,
        width: '50%',
        maxWidth: '100%',
        height: 200,
    },
    leftorderItem: {
        flex: 0.7,
        textAlign: 'start',
        fontSize: 16,
        // background: '#285b97',
        borderRadius: 3,
        marginLeft: 5,
        marginTop: 5,
    },
    leftlistedItem: {
        flex: 0.7,
        textAlign: 'start',
        fontSize: 16,
        fontWeight: "bold",
        // background: '#285b97',
        borderRadius: 3,
        marginLeft: 5,
        marginTop: 5,
    },
    rightlistedItem: {
        flex: 0.3,
        textAlign: 'end',
        fontSize: 16,
        // background: '#285b97',
        borderRadius: 3,
        marginLeft: 5,
        marginTop: 5,
    }, orderItem: {
        fontSize: 16,
        // background: '#285b97',
        fontWeight: "bold",
        borderRadius: 3,
        marginLeft: 5,
        textDecorationLine: 'underline',
        marginTop: 5,
    },
});

export default OrderDetail;